<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/tags">Ticket Tags</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a :href="`/tags/${code}`">{{code}}</a>
    </div>
    <div class="row issue-wrapper" v-if="tag._id">
      <div class="col-md-6 col-lg-4 p-4 issue-col bg-white">
        <h6 class="pt-2 text-dark text-header">Tag Name</h6>
        <input class="form-control" placeholder="Ticket tag name" v-model="tag.name"/>
        <br>
        <h6 class="pt-2 text-dark text-header">Tag Code</h6>
        <input class="form-control" placeholder="Ticket tag code" v-model="tag.code" disabled/>
        <br>
        <h6 class="pt-2 text-dark text-header">Tag Type</h6>
        <ModelSelect
          v-model="tag.type"
          placeholder="Search tag type"
          :options="options.type"
          :isDisabled="true"
        >
        </ModelSelect>
        <br>
        <h6 class="pt-2 text-dark text-header">
          Is Locked &nbsp;&nbsp;
          <!-- <i class="fas fa-info-circle cursor-pointer" v-tooltip="`Ticket tags that are classified as locked can never be changed or removed once tagged to a ticket.`"></i> -->
        </h6>
        <ModelSelect
          v-model="tag.isLocked"
          placeholder="Choose if tag is locked or not"
          :options="options.locked"
          :isDisabled="true"
        >
        </ModelSelect>
        <br>
        <h6 class="pt-2 text-dark text-header">Description</h6>
        <textarea class="form-control" rows="5" placeholder="Write something about this tag" v-model="tag.description"></textarea>
        <br>
        <div class="col-12">
          <br>
          <div class="w-100 text-right">
            <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="updateTagDetails">
              <i class="btn p-0 text-white fas fa-save"></i>&nbsp; Update tag details
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 border-left p-4 issue-col bg-white">
        <h6 class="pt-2 text-dark text-header">Access management</h6>
        <ModelSelect
          v-model="formControl.client"
          placeholder="Search client"
          :options="options.clients"
        >
        </ModelSelect>
        <div class="w-100 mt-4 text-right">
          <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="addClient">
            <i class="fas fa-plus"></i>&nbsp; Add client
          </div>
        </div>
        <br>
        <div class="mb-4 pb-3 border-bottom"></div>
        <div v-if="formControl.clients && formControl.clients.length > 0">
          <div class="row mt-2" v-for="client in formControl.clients" v-bind:key="client.key">
            <small class="col text-left">{{client.name}}</small>
            <div class="col-2">
              <div class="btn btn-sm btn-warning" v-on:click="removeClient(client)">
                <i class="fas fa-minus"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 text-center text-muted my-5 py-5" v-else>
          Ticket tag is available for all clients
        </div>
      </div>
      <div class="col-lg-5 border-left p-4 issue-col bg-white">
        <div class="p-5 m-5 text-center text-muted" v-if="tag.type !== `issueClassification`">
          Custom forms are only for Issue Classification / Templates
        </div>
        <div v-else>
          <h6 class="pt-2 text-dark text-header">Custom forms</h6>
          <div class="row p-0 m-0">
            <div class="col-6 p-1">
              <input type="text" class="form-control" placeholder="Field name" v-model="formControl.form.name">
            </div>
            <div class="col-6 p-1">
              <input type="text" class="form-control" placeholder="Field key" v-model="formControl.form.key">
            </div>
            <div class="col-6 p-1">
              <ModelSelect
                v-model="formControl.form.type"
                placeholder="Field type"
                :options="options.form.types"
              >
              </ModelSelect>
            </div>
            <div class="col-6 p-1">
              <ModelSelect
                v-model="formControl.form.required"
                placeholder="Required field"
                :options="options.form.required"
              >
              </ModelSelect>
            </div>
            <div class="col-12 p-1">
              <textarea
                class="form-control"
                rows="2"
                v-model="formControl.form.options"
                v-if="formControl.form.type === 'select'"
                placeholder="Select options (comma separated - Ex. Option 1, Option 2, etc.)"
              >
              </textarea>
            </div>
          </div>
          <div class="w-100 mt-4 text-right">
            <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="addForm">
              <i class="fas fa-plus"></i>&nbsp; Add Form Field
            </div>
          </div>
          <br>
          <div class="mb-4 pb-3 border-bottom"></div>
          <h6 class="pt-2 text-dark text-header">Sample HTML:</h6>
          <div class="px-3" v-if="formControl.forms && formControl.forms.length > 0">
            <div class="row mt-4" v-for="form in formControl.forms" v-bind:key="form.key">
              <div class="col text-left" v-tooltip="`Field key is ${form.key} and is ${form.required ? '' : 'not '} required`">
                <h6 class="text-dark text-header">{{form.name}}</h6>
                <input type="text" class="form-control" :placeholder="form.name" v-if="form.type === 'text'">
                <input type="number" class="form-control" :placeholder="form.name" v-if="form.type === 'number'">
                <textarea rows="3" class="form-control" :placeholder="form.name" v-if="form.type === 'textarea'"></textarea>
                <ModelSelect
                  v-model="formControl.sampleSelect"
                  :placeholder="form.name"
                  :options="$buildOptions(form.options)"
                  v-if="form.type === 'select'"
                >
                </ModelSelect>
              </div>
              <div class="col-1">
                <h6 class="text-dark text-header">&nbsp;</h6>
                <div class="btn btn-sm btn-warning" v-on:click="removeForm(form)">
                  <i class="fas fa-minus"></i>
                </div>
              </div>
            </div>
            <br>
          </div>
          <div class="w-100 text-center text-muted my-5 py-5" v-else>
            No additional fields
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ModelSelect } from 'vue-search-select'

  export default {
    components: {
      ModelSelect
    },
    props: {
      code: String
    },
    data() {
      return {
        user: this.$userData.user,
        type: 'issueClassification',
        tag: {},
        tags: [],
        options: {
          clients: [],
          type: this.$tagTypes,
          locked: [
            { key: 'true', text: 'Locked', value: true },
            { key: 'false', text: 'Unlocked', value: false }
          ],
          form: {
            required: [
              { key: 'true', text: 'Required', value: true },
              { key: 'false', text: 'Optional', value: false }
            ],
            types: [
              { key: 'text', text: 'Text', value: 'text' },
              { key: 'number', text: 'Number', value: 'number' },
              { key: 'textarea', text: 'Text Area', value: 'textarea' },
              { key: 'select', text: 'Select', value: 'select' }
            ]
          }
        },
        formControl: {
          client: {},
          clients: [],
          form: {
            name: '',
            key: '',
            type: '',
            required: false,
            options: ''
          },
          forms: [],
          sampleSelect: ''
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getTag(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/tags/${this.code}?isInternal=${this.$internalMode}&&type=${this.type}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          await this.validateUser(response.data.isValid, response.data.exists)
          this.tag = response.data.tag
          this.formControl.clients = response.data.tag.clients
          this.formControl.forms = response.data.tag.forms || []
          this.loader(false);
          await this.getClients()
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getTags(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/tags?isInternal=${this.$internalMode}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.tags = response.data.tags
          this.type = this.tags.find(r => r.code === this.code).type
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
        await this.getTag()
      },
      async getClients(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.clients = response.data.clients.map(r => {
            return { key: r._id, value: r._id, text: r.name, record: r }
          }).filter(r =>
            !this.tag.clients.map(q => q._id).includes(r.key)
          )
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      updateTagDetails: function (){
        let errors = []
        if(this.tag.name.trim() === '')
          errors.push('Ticket tag name cannot be empty')
        if(this.tag.code.trim() === '')
          errors.push('Ticket tag code cannot be empty')
        if(this.tags.filter(r => r._id != this.tag._id).map(r => r.code).includes(this.tag.code))
          errors.push('Ticket tag code already exists, try another one')
        if (errors.length > 0){
          errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'Ticket Tag Update',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.updateTag({
            name: this.tag.name,
            code: this.tag.code,
            type: this.tag.type,
            isLocked: this.tag.isLocked,
            description: this.tag.description
          })
        }
      },
      async updateTag(tag, successMessage){
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/tag`,
            {
              tag: tag,
              _id: this.tag._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.tag._id) {
            this.getTag()
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: successMessage || 'Your changes have been saved.'
            })
            return true
          }
          return false
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
          return false
        }
      },
      addClient: async function(){
        let request = this.formControl.clients.concat([this.formControl.client.record]).map(r => r._id)
        if(await this.updateTag(
          { clients: request },
          `${this.formControl.client.text} has been given access to this tag`
        )){
          this.formControl.clients.push(this.formControl.client.record)
          this.options.clients = this.options.clients.filter(r => r.key !== this.formControl.client.key)
          this.formControl.client = {}
        }
      },
      removeClient: async function(client){
        let request = this.formControl.clients.filter(r => r._id !== client._id).map(r => r._id)
        if(await this.updateTag(
          { clients: request },
          `${client.name}'s access to this tag has been removed`)
        ){
          this.formControl.clients = this.formControl.clients.filter(r => r._id !== client._id)
          this.options.clients.push(client)
        }
      },
      addForm: async function(){
        if(this.validateForm(this.formControl.form)){
          let request = this.formControl.form
          request.options = request.options.split(',').map(r => r.trim())
          request = this.formControl.forms.concat([request])

          if(await this.updateTag(
            { forms: request },
            `${this.formControl.form.name} has been added`
          )){
            this.formControl.forms.push(this.formControl.form)
            this.formControl.form ={
              name: '',
              key: '',
              type: '',
              required: false,
              options: ''
            }
          }
        }
      },
      removeForm: async function(form){
        if(await this.updateTag(
          { forms: this.formControl.forms.filter(r => r.key !== form.key) },
          `${form.name} has been removed`
        ))
          this.formControl.forms = this.formControl.forms.filter(r => r.key !== form.key)
      },
      validateForm: function(form){
        let errors = []
        if(form.name.trim() === '')
          errors.push('Field name cannot be empty')
        if(form.key.trim() === '')
          errors.push('Field key cannot be empty')
        if(form.type.trim() === '')
          errors.push('Field type is required')
        if(form.type === 'select' && form.options.trim() === '')
          errors.push('Add atleast one option')
        if(this.formControl.forms.filter(r => r.key === form.key).length > 0)
          errors.push('Field key already exists')

        errors.reverse().forEach(error => {
          this.$alertify({
            group: 'notification',
            title: 'Custom Forms',
            type: 'warn',
            text: error
          })
        });
        return errors.length === 0
      },
      validateUser: async function(isValid, exists){
        if(!isValid || !exists){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: exists ? `You don't have access to this ticket tag.` : `Ticket tag does not exist`
          })
          await this.$sleep(500);
          this.$router.push('/tags')
        }
      }
    },
    mounted: function() {
      document.title = `Ticket Tags | iRipple Helpdesk`;
      this.getTags()
    }
  }
</script>
